import { LOCATION } from '@constants/types';
import { HeroLandingView } from '@features/page-blocks/hero-landing-view';
import { TYPE_DYNAMIC_LANDING_PAGE } from '@infra/cms/types';
import { findContentGuideHeadings } from '@utils/find-content-guide-headings';
import PropTypes from 'prop-types';
import React from 'react';

import { AdvantagePanel } from './advantage-panel';
import { ContentPanel } from './content-panel';
import { ArticlesBox, Wrapper } from './styled-components';

const DynamicLandingPage = props => {
  const {
    pageData: {
      image,
      imageAltTag,
      headerTitle,
      headerDescription,
      categoryUrl,
      categoryTitle,
      showQuoteForm,
      mode,
      imageLocation,
      pageType,
      updatedAt,
      readingTime,
    },
    pageContent,
    location,
  } = props;

  const heroProps = {
    image,
    imageAltTag,
    header: headerTitle,
    emphasizedText: headerDescription,
    showQuoteForm,
    mode,
    imageLocation,
    pageType,
    updatedAt,
  };

  const contentGuide = findContentGuideHeadings(pageContent);

  return (
    <Wrapper>
      <HeroLandingView {...heroProps} />
      <AdvantagePanel />
      <ContentPanel
        content={pageContent}
        title={headerTitle}
        categoryUrl={categoryUrl}
        categoryTitle={categoryTitle}
        contentGuide={contentGuide}
        location={location}
        readingTime={readingTime}
        updatedAt={updatedAt}
      />
      <ArticlesBox title="knowledgeSeries.youMayAlsoLike" />
    </Wrapper>
  );
};

DynamicLandingPage.propTypes = {
  pageData: TYPE_DYNAMIC_LANDING_PAGE.isRequired,
  pageContent: PropTypes.string.isRequired,
  location: LOCATION.isRequired,
};

export { DynamicLandingPage };
