import { ArticlesBox as ArticlesBoxBase } from '@features/articles-box';
import styled from 'styled-components';

const ArticlesBox = styled(ArticlesBoxBase)`
  width: 95%;
  max-width: 1440px;
  margin: 100px auto;
  padding: 0 15px;

  @media ${props => props.theme.query.tablet} {
    width: ${props => props.theme.breakpoint.md}px;
    margin: 50px auto;
  }

  @media ${props => props.theme.query.mobile} {
    margin: 20px auto;
  }
`;

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.white};
`;

export { Wrapper, ArticlesBox };
