import { APPEARANCE_MODE } from '@constants/theme';
import { DynamicLandingPage } from '@features/dynamic-landing-page';
import { LayoutDefault } from '@features/layouts/layout-default';
import { APPEARANCE_MODE_TO_HEADER_TYPE_MAP } from '@features/layouts/layout-default/utils';
import { SEO } from '@features/seo';
import { TYPE_DYNAMIC_LANDING_PAGE } from '@infra/cms/types';
import { gatsbyPageProps } from '@infra/gatsby/types';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Template = props => {
  const {
    pageContext: { locale },
    location,
    data: {
      cms,
      md: {
        html,
        fields: { readingTime },
      },
    },
  } = props;

  const {
    metaTitle: title,
    metaDescription: description,
    metaIndex: index,
    metaFollow: follow,
    metaCanonicalURLOverride: canonical,
    pageType,
    updatedAt,
  } = cms;

  const mode = cms?.mode || APPEARANCE_MODE.DARK_MODE;

  const headerType = APPEARANCE_MODE_TO_HEADER_TYPE_MAP[mode];

  return (
    <LayoutDefault locale={locale} location={location} headerType={headerType}>
      <SEO
        location={location}
        title={title}
        description={description}
        isNoIndex={index === 'noindex'}
        isNoFollow={follow === 'nofollow'}
        canonicalURLOverride={canonical}
        lang={locale}
        pageType={pageType}
        updatedAt={updatedAt}
      />
      <DynamicLandingPage
        location={location}
        pageData={{ ...cms, readingTime }}
        pageContent={html}
      />
    </LayoutDefault>
  );
};

const query = graphql`
  query($id: String!) {
    cms: strapiDynamiclandingpage(id: { eq: $id }) {
      ...PageData
    }

    md: markdownRemark(parent: { parent: { id: { eq: $id } } }) {
      html
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;

Template.propTypes = {
  ...gatsbyPageProps,
  data: PropTypes.shape({
    cms: TYPE_DYNAMIC_LANDING_PAGE,
    md: PropTypes.shape({
      html: PropTypes.string,
    }),
  }).isRequired,
};

export default Template;
export { query, Template };
